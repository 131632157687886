<template>
  <div>
    <New />
  </div>
</template>

<script>
import New from "../components/detailedNew.vue";
export default {
  components: { New },
};
</script>